.card__ques{
    box-shadow: 2px 5px 2px 5px rgb(212, 212, 212);
    padding-bottom: 5%;
    text-align: center;
    border-radius: 15px;
    border: 1px solid rgb(15, 207, 207)
}
.card__index__question{
    border-bottom: 1px solid silver;
    margin-bottom: 2rem;
    padding: 5px;
}
.card__question__question{
    color: rgb(10, 10, 24);
    margin-bottom: 10px;
    word-break: break-word;
}
.card__question__body{
    display: flex;
}
.card__answer{
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 8%;
}
.btn__choice{
    border-radius: 10px;
    margin:5px auto;
    margin: 5px;
    width: 50%;
    padding: 10px;
    padding-left: 15px;
    color: black;
    text-align: left !important;
}
.btn__choice:focus{
    box-shadow: 0px 1px 0px 1px rgb(214, 205, 205) !important;
}
.btn__choice:hover{
    box-shadow: 1px 3px 1px 3px rgb(226, 216, 216);
    position: relative;
    top: -3px;
}


/* Exam CSS */
.row__top{
    display: flex; 
    justify-content: space-between;
}
.card__pagination{
    width: fit-content;
    word-break: break-word ;
    border: 1px solid rgb(15, 207, 207);
    border-radius: 15px;
    display: flex;
    justify-content: center;
}
.countdown__clock{
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(107, 98, 98);
    border-radius: 20px;
    width:fit-content;
    padding:5px;
    text-align: right;
}
.col__main{
    position: relative;
}
.col_btn_pagination{
    display: flex;
    justify-content: center;
}
.btn__pagination{
}

.btn__pagination:focus{
    box-shadow: none;
    visibility: hidden;
}
.btn__pagination:hover{
    box-shadow: none;

}
.img__pagination{
    width: 40px;
    height: 40px;
}
.submit-link{
    color: #000;

}
/* .back-card{
    border: 1px solid lightblue;
    width: fit-content;
    align-items: center;
} */