:root {
    --red: #ff0000; 
    --cornflowerblue: #5A80FB;
    --shamrock: #31ce8a;
    --tundora: #444444;
    --mineshaft: #3C3C3C;
    --scorpion: #5C5C5C;
    --gray: #848484;
    --dovegray: #717171;
    --bizarre: #EDDED4;
    --jaffa: #F37D3E;
    --eveningsea: #04544C;
    --tangaroa: #041E44;
    --bluewhale: #042F3D;
    --bluewhale2: #04284E;
    --tealblue: #04434E;
    --tealblue2: #043D5E;
    --bluestone: #046060;
    --congressblue: #043A81;
    --blackpearl: #04162E;
    --dodgerblue: #4381FF;
    --azure: #345BA6;
    --rhino: #2C3C66;
    --pickledbluewood: #2C3454;
    --ebonyclay: #242C44;
    --bleachedcedar: #2C2438;
    --cocoabrown: #302424;
    --hawkesblue: #DEE6FE;
    --grannyapple: #D6F5E8;
    --flamingo: #F1503E;
    --seabuckthorn: #F9AB34;
    --orangeroughy: #D45713;

    /* nhom 1: analogous */
    --casablanca:#FAAE4B;
    --tanhide:#F69756;
    --coral:#FA744B;

    /* nhom 2: monochromatic */
    --christine:#F26A0D;
    --tango:#f37926;
    --jaffa: #f5883e;
    /* --tanhide:#F69756; */
    --rajah:#f7a66e;
    --hitpink:#F9B586;
    --corvette:#fac49f;

    /* nhom 3 triadic */
    --screamingreen:#56F697;
    /* --tanhide:#F69756; */
    --heliotrope:#9756F6;

    /* nhom 4 complementary */
    /* --tanhide:#F69756; */
    --malibu:#56ECF5;

    /* nhom 7 tetradic */
    --olivegreen:#9FB350;
    /* --tanhide:#F69756; */
    --scooter:#30B7BF;
    --purpleheart:#9139BF;
}