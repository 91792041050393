@import '../../theme/color.css';

.dark-background-1 {
  background-color: #212121;
}
.dark-background-2 {
  background-color: #2e2e2e;
}

.text-align-justify {
  text-align: justify;
}

.bg-primary {
  background-color: var(--rajah) !important;
}