.col-index{
    width: 4%;
}
.col-question{
    width: 50%;
}
.col-your-answer{
    width: 10%;
}
.col-correct-answer{
    width: 10%;
}