html {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  /* card-list */
  body {
    --spacing: 25px;
    --columns: 4;
    --subtitle: 13px;
  }
  .card-course {
    border-radius: 20px;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(31, 31, 32, 0.2) 0px 7px 29px 0px;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }
  .card-img-top {
    object-fit: cover;
  }
  .card-top {
      padding-bottom: 1rem;
  }
  .card-title {
    font-weight: bold;
    color: black;
    background-color: white;
    /* background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    
  }
  .card_course_name{
    height: 60px;
  }
  .card-user {
    display: flex;
    align-items: center;
  }
  .card-user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100rem;
    object-fit: cover;
  }
  .card-user-info {
    padding-left: 20px;
    flex: 1;
  }
  .card-user-top {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .card-user-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding-top: 7px;
  }
  #popovers-icon {
    margin-top: 1.5rem;
  }
  .popover-header {
    background-color: white;
    background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .card-user-top ion-icon {
    color: #20e3b2;
    margin-left: 5px;
  }
  .card-bottom {
    padding: 5px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-live {
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    background-color: #f06957;
    margin-right: 10px;
  }
  .card-live span {
    font-size: 14px;
  }
  .card-watching {
    font-size: 13px;
    font-weight: 300;
  }
  .card-time {
    font-weight: 300;
    font-size: var(--subtitle);
  }
  .row__title {
    margin: 10px ;
  }

  /* Course List */
  .component-top{
    justify-content: space-between;
  }
  .component-top-title{
    /* background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent; */
      text-align: left;
      /* font-family: 'Patrick Hand', cursive; */
      color: #000;
  }
  .component-top-button {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .link__to{
    color: white;
  }
  