.date-row{
    border-left: 5px solid blue;
    background-color: rgb(219, 225, 245);
    color: black;
    height: fit-content;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}
.lecture-title{
    width: fit-content;
}
.lecture-date{
    width: fit-content;
}
.course-name-row{
    font-size: 30px;
    font-weight: bold;
}
.document-row{
    padding-left: 50px;
    padding-top: 5px;
    margin-top: 5px;
}
.document-title-row{
    border-left: 3px solid rgb(223, 155, 10);
    margin-bottom: 5px;
}
.document-detail-row:focus{
    box-shadow: none;
}
.expert-info{
    border-top: 1px solid #303030;
    margin-top: 10px;
    margin-bottom: 3rem;
}
.image-col{
    margin-top: 1.5rem;
}
.infor-col{
    margin-top: 1.5rem;
}
.expert-name{
    margin-top: 10px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width:fit-content;
}
.expert-img{
    width: 120px;
    height:100px;
    border-radius: 8rem;
    object-fit: cover;
  }
.title-row{
    color: black;
}
.exam-link:focus{
    box-shadow: none;
    border: none;
}
.expert-col{
    border-left: 3px solid rgb(219, 155, 15);
}
.course-col{
    border-left: 3px solid rgb(219, 155, 15);
}