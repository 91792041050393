#root{
    --button-icon-width: 2rem;
}
.card-main-expert{
    padding-bottom: 5px;
    position: relative;
}
.card-expert{
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    box-shadow: rgba(31, 31, 32, 0.2) 0px 7px 20px 0px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
    flex-flow: row;
  }
  .column-start{
      border-right: 1px solid black;
      text-align: center;
  }
  .column-start-item{
    margin-top: 5px;
      font-weight: 400;
      font-size: 12px;
      word-break: break-word;
  }
  .column-item{
      border-left: 3px solid rgb(252, 167, 8) ;
      margin-bottom: 2rem;
      padding-left:5px;
      word-break: break-word;
  }
  .expert-avatar{
    width: 80px;
    height:80px;
    border-radius: 100rem;
    object-fit: cover;
  }
  .column-edit{
      justify-content: space-between;
  }
  .button-icon{
    width: var(--button-icon-width);
    height: var(--button-icon-width);
    align-items: center;
  }
  .card-thumnail{
    position: absolute;
    left: 40%;
    bottom: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100rem;
    object-fit: cover;
  }

  /* Modal Css  */
  .modal-title {
    color: black;
    font-weight: bold;
    /* background-image: linear-gradient(to bottom right, #00aefd, #ffa400);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent; */
      text-align: center;
  }
  .form-input {
    padding: 0 20px;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    transition: 0.25s ease;
  }
  .form-input:focus {
    border-color: #6a5a9f;
    box-shadow: none;
  }
  .form-field {
    position: relative;
    margin: 15px;
  }
  .form-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 21px;
    user-select: none;
    color: #999;
    pointer-events: none;
    transition: 0.25s ease;
  }
  .form-input:not(:placeholder-shown) + .form-label,
  .form-input:focus + .form-label {
    color:#6a5a9f;
    top: 0;
    padding: 4px;
    display: inline-block;
    background-color: #fff;
  }

/* Icon Button */
.icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 30px;
  overflow: hidden;
}
.icon::before, .icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease;
  border-radius: 30px;
}
.icon img {
  width: var(--button-icon-width);
  height: var(--button-icon-width);
  position: relative;
  font-size: 30px;
  margin: auto;
  transition: all 0.25s ease;
}
.icon:focus{
  box-shadow: none;
}
.icon::before {
  transition-duration: 1.5s;
  box-shadow: none;
}
.icon:hover::before {
  box-shadow: inset 0 0 0 60px  rgb(223, 222, 221);
}