.dark-background-1 {
  background-color: #212121;
}
.dark-background-2 {
  background-color: #2e2e2e;
}

.text-align-justify {
  text-align: justify;
}

.index-col {
  width: 3%;
}

.fullname-col {
  width: 20%;
}

.birthday-col {
  width: 7%;
}

.gender-col {
  width: 5%;
}

.phone-col {
  width: 10%;
}

.score-col {
  width: 7%;
}

.email-col {
  width: 15%;
}

.sendEmail-col {
  width: 3%;
}

