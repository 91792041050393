.dark-background-1 {
  background-color: #212121;
}
.dark-background-2 {
  background-color: #2e2e2e;
}

.text-align-justify {
  text-align: justify;
}

.index {
  width: 3%;
}

.fullname {
  width: 20%;
}

.birthday {
  width: 10%;
}

.gender {
  width: 5%;
}

.phone {
  width: 10%;
}

.course {
  width: 20%;
}

.trainer {
  width: 20%;
}

.final-score {
  width: 7%;
}

