.pagination{
    flex-direction: row;
    flex-wrap: wrap;
}
.page-item a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
    width: 50px;
    text-align: center;
  }
.page-item a:focus {
    background-color: #4CAF50;
    color: white;
}
.page-item a:hover:not(.focus) {
    background-color: rgb(131, 128, 128);
}
.page-item a:active{
    background-color: rgb(73, 125, 192);
}